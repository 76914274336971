import axios from 'axios';

export const getSuggestedMentoringList = (order, skip, limit) => axios.get('/v1/public/talks/suggested', { params: { order, skip, limit } });
export const getScheduledMentoringList = (order, skip, limit, byIntelzTeacher) => axios.get('/v1/public/talks/accepted', { params: { order, skip, limit, byIntelzTeacher } });

export const getSuggestedKnowledgeTalkDetail = (mentoringId) => axios.get(`/v1/public/talks/suggested/${mentoringId}`);
export const getAcceptedKnowledgeTalkDetail = (mentoringId) => axios.get(`/v1/public/talks/accepted/${mentoringId}`);

/**
 * 멘토링 제안하기.
 * @param title: string             제안하는 멘토링 제목
 * @param content: string           제안하는 멘토링 내용
 * @param relatedCollection: string 연결된 컬렉션 ID
 */
export const suggestMentoring = (title, content, relatedCollection) => axios.post('/v1/private/talks', { title, content, relatedCollection });

/**
 * 제안된 지식톡 수락하기 / 지식톡 생성하기
 * @param id: string          제안된 지식톡 ID (제안된 지식톡 없이 생성하는 경우 보내지 않는다)
 * @param title: string       수락하는 지식톡 제목
 * @param content: string     수락하는 지식톡 내용
 * @param zoomLink: string    zoom ID
 */
export const acceptMentoring = (mentoringInfo) => axios.post('/v1/private/talks/accept', mentoringInfo, { headers: { 'Content-Type': 'multipart/form-data' } });

export const UpdateMentoring = (mentoringInfo, talkId) => axios.put(`/v1/private/talks/accept/${talkId}`, mentoringInfo, { headers: { 'Content-Type': 'multipart/form-data' } });

export const toggleLikeMentoring = (mentoringId) => axios.post(`/v1/private/talks/${mentoringId}/like`);

export const sendChat = (mentoringId, content) => axios.post(`/v1/private/talks/${mentoringId}/chat`, { content });

export const getChatList = (mentoringId) => axios.get(`/v1/public/talks/${mentoringId}/messages`);
