import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import get from 'lodash.get';
import PullToRefresh from 'pulltorefreshjs';
import moment from 'moment';
import { message, Input, Modal as AntModal } from 'antd';
import ReactGA from 'react-ga4';
import { LastLocationProvider } from 'react-router-last-location';
import FooterMenu from './components/atoms/FooterMenu';
import { getHotCollections } from './store/reducers/collection';
import {
  getNotice,
  saveMyUserInfo,
} from './store/reducers/auth';
import { PrivateRoute } from './components/hoc/AuthRoutes';
import storage from './lib/storage';
import ScrollToTop from './components/containers/ScrollToTop';
import Container from './components/atoms/Container';

import {
  setAcceptLanguage,
  setContentLanguage,
  deleteLoginUserInfo,
  getAccessToken,
  setAnonymousHeader,
  setAuthHeader,
} from './utils/authorization';
import { updateProfile } from './store/reducers/producer';
import './App.css';
import 'antd/dist/antd.css';
import imgWelcome from './static/image/welcome_intelzbot.svg';
import { flexCenter } from './utils/mixins';
import {
  Login,
  ResetPassword,
  Page404,
  SearchResultCoverSelection,
  SearchResultCollection,
  CollectionView,
  CollectionEditCollection,
  NeuronPage,
  ProducerList,
  ProducerDetail,
  CollectionPreview,
  UnpublishedCollectionDetail,
  Introduction,
  Mentoring,
  CreateCell,
  ScheduledMentoringList,
  ScheduledMentoringDetail,
  SuggestedMentoringList,
  MyCellHistory,
  SuggestedMentoringDetail,
  SuggestedMentoringAccept,
  FAQ,
  MY,
  MyFollowList,
  ProducerMembershipVerify,
  ProducerMembership,
  Notice,
  HomeCollectionList,
  Privacy,
  ProducerCollectionEdit,
  HomeCollectionCategory,
  HomeCollectionSearchResult,
  Terms,
  CollectionReOrderCell,
  CollectionCreateCell,
  CellSelectCollection,
  DailyIssue,
  UserJisikTripList,
  PublicMemoListPage,
  MemoCreatePage,
  MemoViewPage,
  PaymentPage,
  PaymentResultPage,
  PaymentDetailPage,
  BannerDetailPage,
  BannerListPage,
  YouBookerPage,
  MyPurchaseListPage,
  TogetherNoticeListPage,
  ProducerDetailEditPage,
  PurchaseSettlementPage,
} from './components/pages';
import { onChangePathName } from './utils/googleAnalytics';
import { isReactNativeWebView } from './utils';

import * as privateAPI from './network/api/privateApi';
import translations from './utils/translations';
// const TRACKING_ID = 'UA-202220494-1'; // OUR_TRACKING_ID
// const TRACKING_ID = 'G-MRPPM8XKM1'; // OUR_TRACKING_ID - intelz.kr
const TRACKING_ID = 'G-TP6G8FC38X'; // OUR_TRACKING_ID - youbook.biz
ReactGA.initialize(TRACKING_ID);

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${({ lang }) => (lang === 'vi' ? 'Roboto, sans-serif'
    : lang === 'th' ? 'NotoSansTH400, sans-serif'
      : lang === 'ja' ? 'NotoSansJP400, sans-serif'
        : lang === 'zh' ? 'NotoSansSC400, sans-serif'
          : lang === 'ar' ? 'Noto Sans Arabic, sans-serif'
            : 'Noto Sans CJK KR, sans-serif'
  )};

    direction: ${(props) => (props.lang === 'ar' ? 'rtl' : 'ltr')};
    text-align: ${(props) => (props.lang === 'ar' ? 'right' : 'left')};
  }
`;

const Layout = styled.div`
  color: black;
  background: white;
  width: 100%;
  padding: 28px 0 20px 0;
  @media screen and (min-width: 640px) {
    margin-top: 80px;
    padding: 0;
  }
`;

const Modal = styled(AntModal)`
  .ant-modal-content{
    border-radius: 10px;
  }
  .ant-modal-header {
    display: none;
  }
  .ant-modal-body {
    padding: 40px 24px;
  }
  .ant-modal-close-x {
    display: none;
  }
`;

const StyledModalContent = styled.div`
  ${flexCenter};
  flex-direction: column;
  color: black;
  .welcome-title{
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    padding: 26px 0 20px;
  }
  .welcome-message{
    font-size: 15px;
    font-weight: 500;
  }
  .confirm{
    width: 120px;
    height: 40px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    background: black;
    color: white;
    ${flexCenter}
    border-radius: 30px;
    margin-top: 15px;
  }
`;

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState({ welcome: false, needMoreInfo: false });
  const [moreInfo, setMoreInfo] = useState({ dummydata: '', mobile: '' }); // spread 연산자 사용 위해 dummydata property 사용, 후에 2개 이상이면 삭제
  const location = useLocation();
  const userInfo = useSelector((state) => get(state, ['auth', 'userInfo']));
  const userInfoLoaded = useSelector((state) => get(state, ['auth', 'userInfoLoaded']));
  const userLanguage = useSelector((state) => (get(state, ['auth', 'userLanguage'])));

  useEffect(() => {
    if (!storage.get('userLanguage')) {
      storage.set('userLanguage', navigator.language.split('-')[0]);
    }
    if (!storage.get('contentLanguage')) {
      storage.set('contentLanguage', navigator.language.split('-')[0]);
    }
    setAcceptLanguage(storage.get('userLanguage'));
    setContentLanguage(navigator.language.split('-')[0]);
    if (isReactNativeWebView()) { window.ReactNativeWebView.postMessage(`LANGUAGE_${storage.get('userLanguage')}`); }
  }, []);

  const onMoreInfoChange = (e) => {
    const { name, value } = e.target;
    setMoreInfo({ ...moreInfo, [name]: value });
  };

  const onMoreInfoClick = async () => {
    if (moreInfo.mobile.length !== 11) {
      message.warn('전화번호 11자리를 입력해주세요');
      return;
    }
    const form = new FormData();
    form.append('mobile', moreInfo.mobile);
    const res = await dispatch(updateProfile(form));
    if (res.message === 'success') {
      message.info('입력되었습니다.');
    } else {
      message.error('입력에 실패했습니다.');
    }
    setIsModalVisible((state) => ({ ...state, needMoreInfo: false }));
  };

  useEffect(() => {
    onChangePathName(location.pathname);
  }, [location.pathname]);

  // useEffect(() => {
  //   // window.ChannelIO('hideMessenger');
  //   if (location.pathname.includes('/collection')
  //   || location.pathname === '/my/follows'
  //   || location.pathname.includes('suggested')
  //   || location.pathname === '/'
  //   || location.pathname === '/home/collection'
  //   || location.pathname.includes('/jisikTrip')) {
  //     if (!location.pathname.includes('/search/result/collection')) {
  //       return () => { };
  //     }
  //   }
  //   PullToRefresh.init({
  //     mainElement: '#main-layout',
  //     onRefresh() {
  //       window.location.reload();
  //     },
  //   });
  //   return () => {
  //     PullToRefresh.destroyAll();
  //   };
  // }, [location]);

  // 앱 처음 실행 시, 저장된 accessToken 을 가져오고, accessToken 이 있으면 로그인한 사용자 정보를 다시 한번 조회한다.
  useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken) {
      setAuthHeader(accessToken);
      privateAPI.getMyUserInfo()
        .then((res) => {
          dispatch(saveMyUserInfo(res.data.userInfo));
        })
        .catch((err) => {
          console.log('[ERROR] getMyUserInfo: ', err.message);
          deleteLoginUserInfo();
        });
    } else {
      setAnonymousHeader();
      deleteLoginUserInfo();
    }
  }, []);

  useEffect(() => {
    if (!userInfoLoaded) return;

    if (userInfo) {
      if (userInfo?.id !== '' && userInfo?.mobile === '') {
        setIsModalVisible((state) => ({ ...state, needMoreInfo: true }));
      }

      if (isReactNativeWebView()) {
        const cleanUp = (event) => {
          if (event.data.startsWith('[')) {
            const contacts = JSON.parse(event.data);
            axios.post('/v1/private/contacts', { contacts });
          }
        };
        document.addEventListener('message', cleanUp); // android
        window.addEventListener('message', cleanUp); // ios

        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage('CONTACT_SEND');
        }

        // eslint-disable-next-line consistent-return
        return () => {
          document.removeEventListener('message', cleanUp);
          window.removeEventListener('message', cleanUp);
        };
      }
    } else {
      // storage.set('user', 'false'); // FIXME 이거 필요한가?
    }
  }, [userInfoLoaded, userInfo]);

  useEffect(() => {
    if (userInfo) {
      const { createdAt } = userInfo;
      const showWelcomeMessage = storage.get('showWelcomeMessage');
      const now = moment(new Date());
      const created = moment(createdAt);

      // 개인회원 여부 묻지 않음
      if (!showWelcomeMessage && now.diff(created, 'minutes') < 6) {
        setTimeout(() => {
          setIsModalVisible((state) => ({ ...state, welcome: true }));
          storage.set('showWelcomeMessage', 'true');
        }, [2000]);
      }
    }

    // 유저 알람 가져오기 + 앱에 알람 개수 보내기 (뱃지)
    if (userInfo) {
      dispatch(getNotice()).then((res) => {
        if (isReactNativeWebView()) {
          window.ReactNativeWebView.postMessage(`NOTICE_COUNT_${res.data.notices?.length}`);
        }
      });
      // 유저 아이디 보내기 (기기 토큰과 함께 저장하여 푸시알림 보내기 위해)
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(`USER_ID_${userInfo.id}`);
      }
    }
  }, [userInfo]);

  const fetchSequentialData = async () => {
    try {
      dispatch(getHotCollections('hot')); // 좋아요가 많은 미니북
    } catch (error) {
      console.error('API 요청 중 오류 발생:', error);
    }
  };

  useEffect(() => {
    fetchSequentialData();
  }, []);

  function showMessage() {
    alert('이곳은 컬렉션 페이지입니다. 검색 페이지에서 선택한 셀들을 보여 드립니다.');
    history.push('/search');
  }

  return (
    <Layout>
      <GlobalStyle lang={userLanguage} />
      <ScrollToTop updateWithPath />
      <Route
        path="/collection/:id"
        render={(props) => {
          // eslint-disable-next-line react/prop-types
          const { id } = props.match.params;
          if (id) storage.set('lastVisitedCollection', id);
        }}
      />
      <Modal
        footer={null}
        centered
        visible={isModalVisible.welcome}
        onCancel={() => { setIsModalVisible((state) => ({ ...state, welcome: false })); }}
        width={320}
        height={400}
      >
        <StyledModalContent>
          <div className="welcome-image">
            <img src={imgWelcome} alt="" />
          </div>
          <div className="welcome-title">{translations[userLanguage].register_welcome_message}</div>
          <div className="confirm" onClick={() => { setIsModalVisible((state) => ({ ...state, welcome: false })); }}>
            {translations[userLanguage].button_confirm}
          </div>
        </StyledModalContent>
      </Modal>
      <Modal
        footer={null}
        centered
        visible={isModalVisible.needMoreInfo}
        onCancel={() => { setIsModalVisible((state) => ({ ...state, needMoreInfo: false })); message.error('입력에 실패했습니다.'); }}
        width={320}
        height={400}
      >
        <StyledModalContent>

          <div className="welcome-image">
            <img src={imgWelcome} alt="" />
          </div>
          <div className="welcome-message" style={{ textAlign: 'center', margin: '30px 0' }}>
            원활한 서비스 이용을 위해<br />
            추가 정보가 필요합니다<br />
          </div>
          <Input name="mobile" value={moreInfo.mobile} onChange={onMoreInfoChange} placeholder="핸드폰 번호 ( - 제외하고 입력)" />
          <div className="confirm" onClick={onMoreInfoClick}>
            확인
          </div>
        </StyledModalContent>
      </Modal>
      <div id="main-layout">
        <Container id="container">
          <LastLocationProvider>
            <Switch>
              <Route path="/" component={HomeCollectionList} exact />
              <Route path="/login" component={Login} exact />
              <Route path="/login/:provider/callback" component={Login} exact />
              <Route path="/reset-password" component={ResetPassword} exact />
              <Route path="/search/result/collection/:questionId" component={HomeCollectionSearchResult} />
              <Route path="/search/result/collection" component={HomeCollectionSearchResult} />
              <Route path="/search/result" component={NeuronPage} />
              <Route path="/home/collection/:category" component={HomeCollectionCategory} />
              <Route path="/home/collection" component={HomeCollectionList} />
              <Route path="/neuron/collection/cover" component={SearchResultCoverSelection} />
              <Route path="/neuron/collection/:cellId" component={SearchResultCollection} />
              <Route path="/neuron/collection" component={SearchResultCollection} />
              <Route
                path="/collection"
                exact
                render={() => {
                  const lastVisitedCollection = storage.get('lastVisitedCollection');
                  if (lastVisitedCollection === 'neuron') return <Redirect to="/neuron/collection" />;
                  if (lastVisitedCollection) return <Redirect to={`/collection/${lastVisitedCollection}`} />;
                  return showMessage();
                }}
              />
              <Route path="/collection/preview/:id" component={CollectionPreview} />
              <Route path="/collection/unpublished/:collectionId" component={UnpublishedCollectionDetail} />
              <Route path="/collection/reorder/:id" component={CollectionReOrderCell} /> { /* 컬렉션에서 셀 순서 조정 */}
              <Route path="/collection/create/cell/:collectionId" component={CollectionCreateCell} /> { /* 컬렉션에서 지식셀 생성 */}
              <PrivateRoute path="/collection/:id/edit" component={CollectionEditCollection} />
              <Route path="/collection/:id/:prevSlide" component={CollectionView} />
              <Route path="/collection/:id/" component={CollectionView} />
              <Route path="/producer/membership/verify" component={ProducerMembershipVerify} />
              <Route path="/producer/membership" component={ProducerMembership} />
              <Route path="/producer/:pdId/edit" component={ProducerDetailEditPage} />
              <Route path="/producer/:pdId/:id/collectionEdit" component={ProducerCollectionEdit} />
              <Route path="/producer/:pdId/:id" component={ProducerDetail} />
              <Route path="/producer/:pdId" component={ProducerDetail} />
              <Route path="/producer" component={ProducerList} />
              <Route path="/intro" component={Introduction} />
              <Route path="/mentoring" component={Mentoring} />
              <Route path="/cell/create/:collectionId" component={CreateCell} />
              <Route path="/cell/select/collection/:id" component={CellSelectCollection} /> { /* 지식셀에서 컬렉션에 저장 */}
              <Route path="/cell/update/:id/:collectionId/:previousSelectedLanguage/:prevSlide" component={CreateCell} />
              <Route path="/cell/update/:id/:collectionId/:previousSelectedLanguage" component={CreateCell} />
              <Route path="/cell/update/:id" component={CreateCell} />
              <Route path="/cell/history" component={MyCellHistory} />
              <Route path="/scheduled/:id/:pdId/list" component={TogetherNoticeListPage} />
              <Route path="/scheduled/:id/edit" component={SuggestedMentoringAccept} />
              <Route path="/scheduled/:id" component={ScheduledMentoringDetail} />
              <Route path="/scheduled" component={ScheduledMentoringList} />
              <Route path="/suggested/:id/accept" component={SuggestedMentoringAccept} />
              <Route path="/suggested/:id" component={SuggestedMentoringDetail} />
              <Route path="/suggested" component={SuggestedMentoringList} />
              <Route path="/faq" component={FAQ} />
              <Route path="/my/purchase" component={MyPurchaseListPage} />
              <Route path="/purchaseSettlement" component={PurchaseSettlementPage} />
              <Route path="/my/follows" component={MyFollowList} />
              <Route path="/my" component={MY} />
              <Route path="/notice" component={Notice} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/terms" component={Terms} />
              <Route path="/dailyIssue/:id" component={DailyIssue} />
              <Route path="/user/jisikTrip/:id/:type" component={UserJisikTripList} />
              <Route path="/memo/public/:id" component={MemoViewPage} />
              <Route path="/memo/public" component={PublicMemoListPage} />
              <Route path="/memo/my/:id" component={MemoViewPage} />
              <Route path="/memo/create" component={MemoCreatePage} />
              <Route path="/memo/:id/edit" component={MemoCreatePage} />
              <Route path="/payment/detail" component={PaymentDetailPage} />
              <Route path="/payment/result" component={PaymentResultPage} />
              <Route path="/payment" component={PaymentPage} />
              <Route path="/banner/detail/:id" component={BannerDetailPage} />
              <Route path="/banner/list" component={BannerListPage} />
              <Route path="/youbooker" component={YouBookerPage} />
              <Route component={Page404} />
            </Switch>
          </LastLocationProvider>
        </Container>
        {(!location.pathname.includes('/search/result'))
          && (location.pathname !== '/')
          && (location.pathname !== '/home')
          && (location.pathname !== '/home/cell')
          && (location.pathname !== '/home/collection')
          && <FooterMenu />}
      </div>

    </Layout>
  );
}

export default App;
