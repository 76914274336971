import axios from 'axios';

export const searchContent = (input, searchClass) => axios.get('/v1/public/cells/search', { params: { input, searchClass } });
export const searchKeywords = (keywords, sentence, skip, limit) => axios.get('/v1/public/cells/search/keywords', { params: { keywords, sentence, skip, limit } });
export const getCellsByReference = (referenceTitle, referenceAuthor) => axios.get('/v1/public/cells/by-reference', { params: { referenceTitle, referenceAuthor } });
export const showCells = (cellIds) => axios.post('/v1/public/show', { cellIds });
export const appendCells = (cellIds) => axios.post('/v1/public/append', { cellIds });
export const getCellInfo = (ids) => axios.get('/v1/public/celllist', { params: { ids } });
export const getProducerList = (page) => axios.get('/v1/public/producers', { params: { page } });
export const getUserDetailInfo = (id) => axios.get(`/v1/public/users/${id}`);
export const getFAQList = () => axios.get('/v1/public/faq');
export const getCollection = (id) => axios.get(`/v1/public/collections/${id}`);

export const getRelatedCell = (cellId, limit) => axios.get('/v1/public/cells/related', { params: { cellId, limit } });
export const getProducerCollection = (id, skip, limit) => axios.get(`/v1/public/users/${id}/collections`, { params: { skip, limit } });
export const getProducerCell = (id, skip, limit) => axios.get(`/v1/public/users/${id}/cells`, { params: { skip, limit } });

export const getCollectionsByCategory = (category, skip, limit, skipIds) => axios.get(`/v1/public/collections/by-category/${category}`, { params: { skip, limit, skipIds } });

export const getCollectionsByCategoryAtOnce = () => axios.get('/v1/public/collections/by-category/at-once');

export const increaseCellViewCount = (cellId, language) => axios.post(`/v1/public/cells/${cellId}/${language}/view-count/increase`);

// 특정 컬렉션에 관련된 컬렉션 조회
export const getRelatedCollection = (collectionId, limit) => axios.get(`/v1/public/collections/${collectionId}/related-collections`, { params: { limit } });

// 특정 지식셀이 포함되거나 관련된 컬렉션 조회
export const getContainedCollection = (id) => axios.get(`/v1/public/cells/${id}/related-collections`);

// 사용자 챗 목록 조회
export const getUserChatList = (userId) => axios.get(`/v1/public/users/${userId}/messages`);

// 사용자가 최근 본 지식셀 목록 조회
export const getUserVeiwedCell = (user, skip, limit) => axios.get('/v1/public/cells/viewed', { params: { user, skip, limit } });

// 사용자가 최근 좋아요한 컬렉션 목록 조회
export const getUserLikeCollection = (userId, skip, limit) => axios.get(`/v1/public/collections/liked-by/${userId}`, { params: { skip, limit } });

// 사용자가 최근 좋아요한 모임방 목록 조회
export const getUserLikeKnowledgeTalk = (user, skip, limit) => axios.get('/v1/public/talks/like', { params: { user, skip, limit } });

// 컬렉션 검색
export const searchCollections = (input, searchClass) => axios.post('/v1/public/collections/search', { input, searchClass });

// 랜덤한 하나의 지식셀 조회
export const getRandomCell = () => axios.get('/v1/public/cells/random');

// 키워드 검색시 오타교정
export const editSearchKeywords = (keywords) => axios.post('/v1/public/collections/generate/edit', { keywords });

// 사용자 랭킹 조회
export const getUserRanking = (keyword, skip, limit) => axios.get('/v1/public/users/ranking', { params: { keyword, skip, limit } });

// Gather 정보 조회
export const getGatherInfo = () => axios.get('/v1/public/talks/gather-rooms');

// 유저 지식 여행기 조회
export const getUserJisikTripInfo = (id) => axios.get(`/v1/public/users/${id}/activity-summary`);

// 공개 메모 조회
export const getPublicMemoList = (skip, limit) => axios.get('/v1/public/memos', { params: { skip, limit } });

// 공개 메모 조회(특정유저)
export const getPublicMemoByIdList = (userId, keyword, collectionId, cellId) => axios.get('/v1/public/memos', { params: { userId, keyword, collectionId, cellId } });

// 공개 메모 키워드 조회
export const getPublicMemoKeywordList = (userId) => axios.get('/v1/public/memos/keywords', { params: { userId } });

// 메모 상세 조회
export const getMemoDetail = (id) => axios.get(`/v1/public/memos/${id}`);

// 메모 댓글 조회
export const getMemoChatList = (memoId) => axios.get(`/v1/public/memos/${memoId}/messages`);

// 문장 핵심어 추출
export const getSummary = (text) => axios.get('/v1/public/cells/summary', { params: { text } });

// 배너 목록 조회
export const getBannerList = () => axios.get('/v1/public/questions');

// 배너 상세 조회
export const getBannerDetail = (id) => axios.get(`/v1/public/questions/${id}`);

// 새로 나온 미니북 조회 (지식탐구독자가 7일 이내에 작성한 미니북 목록)
export const getNewCollections = (skip, limit) => axios.get('v1/public/collections/new', { params: { skip, limit } });

// AI 와 함께, 오늘의 미니북 조회
// 프로듀서가 생성하고, AI 가 만든 페이지를 포함하는 미니북을 랜덤으로 조회.
// 단, 24시간 내에 새로 만들어진 미니북이 있다면 리스트 제일 앞에 포함됨.
export const getTodayCollections = () => axios.get('v1/public/today-collections');

// 투게더 공지사항 조회
export const getTogetherNoticeList = (togetherId) => axios.get(`/v1/public/togethers/${togetherId}/notices`);

// 미니북 조회 (언어별)
export const getCollectionByLanguage = (id, language) => axios.get(`/v1/public/collections/${id}?language=${language}`);

// 페이지 조회 (언어별)
export const getCellByLanguage = (id, language) => axios.get(`/v1/public/cells/${id}?language=${language}`);
