import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash.get';
import DOMPurify from 'isomorphic-dompurify';
import { flexCenter } from '../../../utils/mixins';
import MediaView from '../../containers/MediaView';
import storage from '../../../lib/storage';
import { getTodayCollections } from '../../../store/reducers/collection';
import translations from '../../../utils/translations';

import imgBook from '../../../static/image/menu_minibook_w.svg';
import imgBookActive from '../../../static/image/menu_minibook_w_active.svg';
import imgMemo from '../../../static/image/menu_memo_w.svg';
import imgMemoActive from '../../../static/image/menu_memo_w_active.svg';
import imgTogether from '../../../static/image/menu_together_w.svg';
import imgTogetherActive from '../../../static/image/menu_together_w_active.svg';
import imgYouBooker from '../../../static/image/menu_youbooker_w.svg';
import imgYouBookerActive from '../../../static/image/menu_youbooker_w_active.svg';

import imgBookDark from '../../../static/image/menu_minibook_d.svg';
import imgBookDarkActive from '../../../static/image/menu_minibook_d_active.svg';
import imgMemoDark from '../../../static/image/menu_memo_d.svg';
import imgMemoDarkActive from '../../../static/image/menu_memo_d_active.svg';
import imgTogetherDark from '../../../static/image/menu_together_d.svg';
import imgTogetherDarkActive from '../../../static/image/menu_together_d_active.svg';
import imgYouBookerDark from '../../../static/image/menu_youbooker_d.svg';
import imgYouBookerDarkActive from '../../../static/image/menu_youbooker_d_active.svg';

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
`;

const StyledMenu = styled.div`
  width: 100%;
  height: 70px;
  border-radius: 50px 50px 0 0;
  position: fixed;
  bottom: 0;
  background: ${(props) => props.color};
  box-shadow: 0 -2px 4px rgb(0 0 0 / 5%);
  z-index: 99;
  ${flexCenter};
  .menu{
    color: #a0a4a8;
  }
  .menu.active{
    color: ${(props) => props.fontColor};
    font-weight: 600;
    @media (min-width: 640px) {
      background: #f4f4f4;
    }
  }
  
  .inner-block{
    width: 100%;
    display: flex;
    margin: 0 5px;
  }
  @media (min-width: 640px) {
    height: 80px;
    position: absolute;
    .inner-block{
      width: 414px;
      height: 80px;
    }
  }
`;

const MenuLink = styled(NavLink)`
  width: 20%;
  position: relative;
  margin: 0 auto;
  font-size: 8px;
  ${flexCenter}
  flex-direction: column;
  span{
    margin-top: -2px;
  }
  .inline{
    text-align: center;
    img{padding-bottom: 1px;}
  }
  @media (min-width: 640px) {
    width: 83px;
    :hover{
      background: #f4f4f4;
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  height: 330px;
  background-color: #2E2E2E;
  color: #fff;
  font-weight: 500;
  ${flexCenter}
  .logo{
    width: 290px;
    height: 184px;
    padding-top: 45px;
    border-right: 0.5px solid  rgba(255, 255, 255, 0.5);
  }
  .text-block{
    font-size: 14px;
    letter-spacing: -0.5px;
    width: 500px;
    padding-left: 56px;
    .divider{padding: 0 15px;}
    .title{margin-right: 39px;font-size: 12px;font-weight: 500;}
    .text1{padding-bottom: 20px;}
    .contact1{margin-bottom: 15px;line-height: 20px;}
    .contact2{display: inline-flex;margin-bottom: 15px;}
  }
  .span{margin-left: 60px;}
`;

const CopyRight = styled.div`
  width: 100%;
  height: 45px;
  background: black;
  color: white;
  font-size: 12px;
  ${flexCenter};
  font-weight: 300;
`;

const FooterMenu = () => {
  const dispatch = useDispatch();
  const [pathName, setPathName] = useState('/');
  const location = useLocation();
  const userLanguage = useSelector((state) => (get(state, ['auth', 'userLanguage'])));
  const isDarkMode = pathName === '/' || pathName.indexOf('/home/collection') === 0;

  const getMenuImage = (defaultImg, activeImg, darkImg, darkActiveImg, isActive) => {
    if (isDarkMode) {
      return isActive ? darkActiveImg : darkImg;
    }
    return isActive ? activeImg : defaultImg;
  };

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  const initScrollPosition = () => {
    storage.set('lastScrollPosition', 0);
    window.scrollTo(0, 0);
    // TO DO: 추후에 다른 방법 찾아볼 것
    document.body.style.overflow = 'auto'; // 메뉴 이동시 팔로워/팔로잉에서 페이지 고정되는 문제로 수정
    dispatch(getTodayCollections()); // 메뉴 이동시 오늘의 미니북 새로고침
  };

  if (window.isNativeApp) {
    return null;
  }

  return (
    <Wrapper>
      <MediaView maxWidth={639}>
        <StyledMenu
          color={pathName === '/' || pathName.indexOf('/home/collection') === 0 ? 'black' : 'white'}
          fontColor={(pathName === '/' || pathName.indexOf('/home/collection') === 0) ? '#5D9DF0' : 'black'}
        >
          <div className="inner-block">
            <MenuLink
              to="/"
              exact
              onClick={initScrollPosition}
              className="menu"
              activeClassName="active"
              isActive={() => (
                (pathName.indexOf('/producer') === 0 && pathName.indexOf('/myProfile') === 0)
              || pathName.indexOf('/collection') === 0 || pathName === '/'
              || pathName.indexOf('/home/collection') === 0
              || pathName.indexOf('/dailyIssue') === 0
              )}
            >
              <div className="inline">
                <img
                  src={getMenuImage(
                    imgBook,
                    imgBookActive,
                    imgBookDark,
                    imgBookDarkActive,
                    (pathName.indexOf('/producer') === 0 && pathName.indexOf('/myProfile') === 0)
                || pathName.indexOf('/collection') === 0 || pathName === '/'
                || pathName.indexOf('/home/collection') === 0
                || pathName.indexOf('/dailyIssue') === 0,
                  )}
                  alt="intelz"
                />
                <div>{translations[userLanguage].menu_book}</div>
              </div>
            </MenuLink>

            <MenuLink to="/memo/public" onClick={initScrollPosition} className="menu" activeClassName="active" isActive={() => ['/memo/public'].includes(pathName)}>
              <div className="inline">
                <img
                  src={getMenuImage(
                    imgMemo,
                    imgMemoActive,
                    imgMemoDark,
                    imgMemoDarkActive,
                    pathName === '/memo/public' || pathName.indexOf('/memo/public') === 0,
                  )}
                  alt="memo"
                />
                <div>{translations[userLanguage].menu_memo}</div>
              </div>
            </MenuLink>

            <MenuLink to="/mentoring" onClick={initScrollPosition} className="menu" activeClassName="active" isActive={() => ['/mentoring', '/suggested', '/scheduled'].includes(pathName)}>
              <div className="inline">
                <img
                  src={getMenuImage(
                    imgTogether,
                    imgTogetherActive,
                    imgTogetherDark,
                    imgTogetherDarkActive,
                    pathName === '/mentoring' || pathName.indexOf('/scheduled') === 0 || pathName.indexOf('/suggested') === 0,
                  )}
                  alt="mentoring"
                />
                <div>{translations[userLanguage].menu_together}</div>
              </div>
            </MenuLink>

            <MenuLink to="/youbooker" onClick={initScrollPosition} className="menu" activeClassName="active" isActive={() => ['/youbooker'].includes(pathName)}>
              <div className="inline">
                <img
                  src={getMenuImage(
                    imgYouBooker,
                    imgYouBookerActive,
                    imgYouBookerDark,
                    imgYouBookerDarkActive,
                    pathName === '/youbooker',
                  )}
                  alt="youbooker"
                />
                <div>youBooker</div>
              </div>
            </MenuLink>
          </div>
        </StyledMenu>
      </MediaView>
      <MediaView minWidth={640}>
        <Footer>
          <div className="text-block">
            <div className="text1">{translations[userLanguage].home_book_company_info}</div>
            <div>
              <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(translations[userLanguage].home_page_about_detail) }} />
              <br /><br />
              <span
                onClick={() => {
                  window.open(userLanguage === 'zh'
                    ? 'https://youbook.blog/zh-hans/2024/04/terms-of-service/'
                    : `https://youbook.blog/${userLanguage}/2024/04/terms-of-service/`, '_blank');
                }}
                style={{ cursor: 'pointer' }}
              >{translations[userLanguage].terms_title}
              </span>
              <span className="divider">|</span>
              <span
                onClick={() => {
                  window.open(userLanguage === 'zh'
                    ? 'https://youbook.blog/zh-hans/2024/04/privacy-policy/'
                    : `https://youbook.blog/${userLanguage}/2024/04/privacy-policy/`, '_blank');
                }}
                style={{ cursor: 'pointer' }}
              >{translations[userLanguage].privacy_title}
              </span>
            </div>
          </div>
        </Footer>
        <CopyRight>
          Copyright © 2022 NICK syndicate. All rights reserved.
        </CopyRight>
      </MediaView>
    </Wrapper>
  );
};

export default FooterMenu;
