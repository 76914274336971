/* eslint-disable no-plusplus */
import { message } from 'antd';

export const parseJSON = (jsonString, fallback = {}) => {
  if (typeof jsonString === 'object') {
    return jsonString;
  }
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error(jsonString);
    console.error(error.message);
    return fallback;
  }
};

export const validatePassword = (value) => {
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/;

  return passwordRegex.test(value);
};

export const escapeRegExp = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const jsonPrettyStringify = (json) => {
  let result = json;
  if (typeof result !== 'string') {
    result = JSON.stringify(result, undefined, 2);
  }
  result = result.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');

  return result.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    (match) => {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return `<span class="${cls}">${match}</span>`;
    },
  );
};

export const getTimeCodeFromNum = (num) => {
  let seconds = parseInt(num, 10);
  let minutes = parseInt((seconds / 60), 10);
  seconds -= minutes * 60;
  const hours = parseInt((minutes / 60), 10);
  minutes -= hours * 60;

  if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
  return `${String(hours).padStart(2, 0)}:${minutes}:${String(
    seconds % 60,
  ).padStart(2, 0)}`;
};

export const isReactNativeWebView = () => !!window.isRNWebView;

export const copyText = (text, onSuccess) => {
  navigator.clipboard.writeText(text)
    .then(() => { if (onSuccess) onSuccess(); })
    .catch(console.error);
};

export const shareUrl = (title, url) => {
  // if (isReactNativeWebView()) {
  //   url = `ploooto:${url.substring(window.location.protocol.length)}`;
  // }

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(`SHARE_${title}\n\n${url}`);
  } else if (navigator.share) {
    navigator.share({
      title,
      url,
    }).then(() => {
      console.log('Thanks for sharing!');
      message.info('공유에 성공했습니다.');
    }).catch((err) => {
      message.error('공유에 실패했습니다.');
      console.error(err);
    });
  } else {
    copyText(url, (() => { message.info('URL을 클립보드에 복사했습니다.'); }));
  }
};

export const dataURLtoFile = (dataurl, fileName) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let num = bstr.length;
  const u8arr = new Uint8Array(num);

  // eslint-disable-next-line no-const-assign
  while (num--) {
    u8arr[num] = bstr.charCodeAt(num);
  }
  return new File([u8arr], fileName, { type: mime });
};

export const getUserProfilePageUrl = (id) => `/producer/${id}?tab=jisikTrip`;

export const parseLinkTextToHTML = (text) => {
  const regURL = new RegExp('(http|https|ftp|telnet|news|irc)://([-/.a-zA-Z0-9_~#%$?&=:200-377]+)', 'gi');
  const regEmail = new RegExp('([xA1-xFEa-z0-9_-]+@[xA1-xFEa-z0-9-]+.[a-z0-9-]+)', 'gi');

  return text
    .replace(regURL, "<a href='$1://$2' target='_blank'>$1://$2</a>")
    .replace(regEmail, "<a href='mailto:$1'>$1</a>");
};

const numbering = (value, division) => {
  const result = Math.floor(value / division);
  return result === 0 ? null : (result % division);
};

const NUMBER_FORMAT_REGX = /\B(?=(\d{3})+(?!\d))/g;
const numberFormat = (value) => value.toString().replace(NUMBER_FORMAT_REGX, ',');
const setUnitText = (numbers) => {
  const unit = ['원', '만', '억', '조', '경'];
  return numbers.map((number, index) => (number ? numberFormat(number) + unit[(unit.length - 1) - index] : number));
};

export const getMoneyFormat = (value) => {
  const numbers = [
    numbering(value % 100000000000000000000, 10000000000000000),
    numbering(value % 10000000000000000, 1000000000000),
    numbering(value % 1000000000000, 100000000),
    numbering(value % 100000000, 10000),
    value % 10000,
  ];

  return setUnitText(numbers)
    .filter((number) => !!number)
    .join(' ');
};

export async function OpenAIStream(payload) {
  const completion = await fetch('https://api.openai.com/v1/chat/completions', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_API_KEY ?? ''}`,
    },
    method: 'POST',
    body: JSON.stringify(payload),
  });

  // Create an EventSource-like object to process the SSE stream
  const eventSource = new EventTarget();
  let buffer = '';
  const decoder = new TextDecoder();

  async function processStream() {
    const reader = completion.body.getReader();
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const { done, value } = await reader.read();
      if (done) break;
      buffer += decoder.decode(value, { stream: true });

      let newlineIndex;
      // eslint-disable-next-line no-cond-assign
      while ((newlineIndex = buffer.indexOf('\n')) !== -1) {
        const line = buffer.slice(0, newlineIndex);
        buffer = buffer.slice(newlineIndex + 1);

        if (line.startsWith('data: ')) {
          const data = line.slice(6);
          if (data === '[DONE]') {
            eventSource.dispatchEvent(new Event('done'));
          } else {
            eventSource.dispatchEvent(new CustomEvent('message', { detail: data }));
          }
        }
      }
    }
  }

  processStream().catch((error) => {
    console.error('Error processing SSE stream:', error);
    eventSource.dispatchEvent(new CustomEvent('error', { detail: error }));
  });

  return eventSource;
}

/**
 * Html String 에서 Text 만 추출.
 * TTS 를 잘 하기위해, 단락마다 '. ' 을 추가함.
 */
export function extractTextFromHTML(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const { body } = doc;

  function addDotToTextNode(node) {
    if (node.nodeType === 3) { // 3은 텍스트 노드를 의미
      const text = node.textContent.trim();
      if (text && !text.endsWith('.')) {
        // eslint-disable-next-line no-param-reassign
        node.textContent += '. ';
      }
    }
    Array.from(node.childNodes).forEach(addDotToTextNode);
  }

  addDotToTextNode(body);
  return body.textContent.trim();
}

const languageMap = {
  ar: 'Arabic',
  bn: 'Bengali',
  ca: 'Catalan',
  cs: 'Czech',
  da: 'Danish',
  de: 'German',
  el: 'Greek',
  en: 'English',
  es: 'Spanish',
  et: 'Estonian',
  fa: 'Persian',
  fi: 'Finnish',
  fil: 'Filipino',
  fr: 'French',
  ga: 'Irish',
  he: 'Hebrew',
  hi: 'Hindi',
  hr: 'Croatian',
  hu: 'Hungarian',
  id: 'Indonesian',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  la: 'Latin',
  lt: 'Lithuanian',
  lv: 'Latvian',
  ms: 'Malay',
  nl: 'Dutch',
  no: 'Norwegian',
  pl: 'Polish',
  pt: 'Portuguese',
  ro: 'Romanian',
  ru: 'Russian',
  sk: 'Slovak',
  sl: 'Slovenian',
  sv: 'Swedish',
  ta: 'Tamil',
  th: 'Thai',
  tr: 'Turkish',
  uk: 'Ukrainian',
  ur: 'Urdu',
  vi: 'Vietnamese',
  zh: 'Chinese',
};

export function getLanguage(lan) {
  return languageMap[lan] || 'Unknown Language';
}
