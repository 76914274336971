import axios from 'axios';

export const getMyUserInfo = () => axios.get('/v1/private/users/my');
export const createCollection = (form) => axios.post('/v1/private/collections', form, { headers: { 'Content-Type': 'multipart/form-data' } });
export const updateCollection = (collectionId, form, language) => axios.put(`/v1/private/collections/${collectionId}/${language}`, form, { headers: { 'Content-Type': 'multipart/form-data' } });
export const updateCollectionCells = (collectionId, cells) => axios.put(`/v1/private/collections/${collectionId}/cells`, { cells });
export const updateProfile = (form) => axios.put('/v1/private/users/my', form, { headers: { 'Content-Type': 'multipart/form-data' } });
export const removeCollection = (collectionId) => axios.post('/v1/private/collection/delete', { collectionId });

/**
 * @deprecated 예전 멤버쉽 API 입니다. 서버에서 더 이상 지원하지 않습니다.
 */
export const registerMembership = (code) => axios.post('/v1/private/register', { code });
export const getNotice = () => axios.get('/v1/private/notices');
export const deleteNotice = (noticeId) => axios.delete(`/v1/private/notices/${noticeId}`);
export const toggleLikeCollection = (collectionId) => axios.post(`/v1/private/collection/${collectionId}/like`);
export const getLikeCollection = (collectionId) => axios.get(`/v1/private/collection/${collectionId}/like`);
export const followUser = (userId) => axios.post(`/v1/private/users/${userId}/follow`);
export const unfollowUser = (userId) => axios.delete(`/v1/private/users/${userId}/follow`);
export const getFollowStatus = (userId) => axios.get(`/v1/private/users/${userId}/follow`);
export const getFollowingList = () => axios.get('/v1/private/users/followings');
export const getFollowerList = () => axios.get('/v1/private/users/followers');
export const readNotice = (noticeId) => axios.patch(`/v1/private/notices/${noticeId}/read`);

// 컬렉션에 셀 추가
export const addCellToCollection = (collectionId, cellId) => axios.post(`/v1/private/collection/${collectionId}/cells/add/${cellId}`);

export const createCustomCell = (customCell) => axios.post('/v1/private/cell', customCell, { headers: { 'Content-Type': 'multipart/form-data' } });
export const updateCustomCell = (cellId, customCell, language) => axios.put(`/v1/private/cell/${cellId}/${language}`, customCell, { headers: { 'Content-Type': 'multipart/form-data' } });
export const deleteCustomCell = (cellId) => axios.delete(`/v1/private/cell/${cellId}`);

// 셀 히스토리 목록 조회
export const getCellHistory = (skip, limit) => axios.get('/v1/private/cells/history', { params: { skip, limit } });

// 내가 본 컬렉션 조회
export const getCollectionHistory = (skip, limit) => axios.get('/v1/private/collections/history', { params: { skip, limit } });

// 사용자 챗 보내기
export const sendUserChat = (userId, content) => axios.post(`/v1/private/users/${userId}/chat`, { content });

// 당신과 비슷한 취향인
export const getUserRelated = (userId, limit) => axios.get('/v1/private/users/related', { params: { userId, limit } });

// 지식톡에 컬렉션 추가
export const addCollectionToJisikTalk = (jisikTalkId, collectionId) => axios.post(`/v1/private/talks/${jisikTalkId}/subject/${collectionId}`);

// 지식톡에 추가된 컬렉션 삭제
export const deleteCollectionToJisikTalk = (jisikTalkId, collectionId) => axios.delete(`/v1/private/talks/${jisikTalkId}/subject/${collectionId}`);

// 부적절 내용 신고
export const reportIllegalContent = (type, id, reason) => axios.post(`/v1/private/report/${type}/${id}`, { reason });

// 지식톡 삭제
export const deleteTalk = (talkId) => axios.delete(`/v1/private/talks/${talkId}`);

// 메모 생성
export const createMemos = (target, targetId, content, keywords, rate, type, isPublic, color) => axios.post('/v1/private/memos', { target, targetId, content, keywords, rate, type, isPublic, color });

// 메모 수정
export const updateMemos = (id, isPublic, content, keywords, rate, color) => axios.put(`/v1/private/memos/${id}`, { isPublic, content, keywords, rate, color });

// 메모 조회
export const getMemoList = (keyword) => axios.get('/v1/private/memos/my', { params: { keyword } });

// 메모 키워드 조회
export const getMemoKeywordList = () => axios.get('/v1/private/memos/keywords/my');

// 메모 상세 조회
export const getMemoDetail = (id) => axios.get(`/v1/private/memos/${id}`);

// 메모 삭제
export const deleteMemo = (memoId) => axios.delete(`/v1/private/memos/${memoId}`);

// 메모 댓글 등록
export const sendMemoChat = (memoId, content, isAiQuestion) => axios.post(`/v1/private/memos/${memoId}/messages`, { isAiQuestion, content });

// 메모 댓글 삭제
export const deleteMessage = (messageId) => axios.delete(`/v1/private/messages/${messageId}`);

// 대댓글 등록
export const replyMessage = (messageId, content, mention) => axios.post(`/v1/private/messages/${messageId}/replies`, { mention, content });

// 회원 탈퇴
export const deleteMyAccountApi = () => axios.delete('/v1/private/users/my');

// 등록 가능한 기관 코드 조회
export const getCodeAvailable = () => axios.get('/v1/private/organizations/codes/available');

// 기관 코드 등록
export const registerCode = (organizationCode, code) => axios.post(`/v1/private/organizations/${organizationCode}/codes/${code}`);

// 기관에 소속되기
export const followOrganization = (code) => axios.post(`/v1/private/organizations/codes/${code}/follow`);

// 포인트 충전 시도하기
export const tryCharge = (tryAmount, subsPeriodYear, organizationId, subsPeople) => axios.post('/v1/private/users/my/points/charge', { tryAmount, subsPeriodYear, organizationId, subsPeople });

// 회원 유효기간 조회
export const getSubscriptionPeriod = () => axios.get('/v1/private/users/my/subscription');

// 메모 좋아요
export const toggleLikeMemo = (memoId) => axios.post(`/v1/private/memos/${memoId}/like`);

// 내가 알 수 도 있는 사용자 목록 조회
export const getYouMayKnowList = () => axios.get('/v1/private/users/you-may-know');

// 나와 관련된 사람 검색
export const getMyPeople = (keyword) => axios.get('/v1/private/users/my-people', { params: { keyword } });

// 인텔즈를 사용하지 않는 사용자 조회
export const getNotIntelzerList = () => axios.get('/v1/private/users/not-intelzer');

// 내 이익 조회
export const getMyBenefit = () => axios.get('/v1/private/users/my/benefit');

// 내 결제 내역 조회
export const getMySubscriptions = () => axios.get('/v1/private/users/my/subscriptions');

// 기관 결제 내역 조회
export const getOrgSubscriptions = () => axios.get('/v1/private/organizations/my/subscriptions');

// 기관에 속한 회원 조회
export const getOrgMembers = () => axios.get('/v1/private/organizations/my/members');

// 기관에 속한 회원 삭제
export const deleteOrgMembers = (userIds) => axios.delete('/v1/private/organizations/my/members', { data: { userIds } });

// 지식북 요청
export const createBanner = (title, content, color) => axios.post('/v1/private/questions', { title, content, color });

// 지식북 요청 수정
export const editBanner = (questionId, title, content, color) => axios.put(`/v1/private/questions/${questionId}`, { title, content, color });

// 지식북 요청 삭제
export const deleteBanner = (id) => axios.delete(`/v1/private/questions/${id}`);

// 지식북 요청 답변 등록 (관리자만 가능)
export const writeBannerAnswer = (questionId, answer) => axios.post(`/v1/private/questions/${questionId}/answers`, { answer });

// 지식북 요청 답변 수정 (관리자만 가능)
export const editBannerAnswer = (answerId, answer) => axios.put(`/v1/private/questions/answers/${answerId}`, { answer });

// 지식북 요청 답변 삭제
export const deleteBannerAnswer = (answerId) => axios.delete(`/v1/private/questions/answers/${answerId}`);

// 지식북 요청에 컬렉션 연결
export const addCollectionToBanner = (questionId, collectionIds) => axios.post(`/v1/private/questions/${questionId}/related-collections`, { collectionIds });

// 유료로 출판할 수 있는지 체크하는 API
export const canMakePaid = (collectionId) => axios.get(`/v1/private/collections/${collectionId}/can-make-paid`);

// 해당 언어로 양도할 수 있는지 체크하는 API
export const canTransfer = (collectionId, language) => axios.get(`/v1/private/collections/${collectionId}/can-transfer/${language}`);

// 미니북 출판
export const requestPublishBook = (collectionId, price, currency, publishStartAt, publishEndAt) => axios.post(`/v1/private/collections/${collectionId}/publish-request`, { price, currency, publishStartAt, publishEndAt });

// 미니북 구매
export const purchaseBook = (collectionId, currency, tryAmount, language) => axios.post(`/v1/private/collections/${collectionId}/purchase`, { currency, tryAmount, language });

// 구매이력 조회
export const getMyPurchaseList = () => axios.get('/v1/private/my-purchases');

// 계좌정보 입력/수정
export const saveMyAccount = (type, values) => axios.put(`/v1/private/users/my/account-info/${type}`, values);

// 내 계좌 조회
export const getMyAccount = () => axios.get('/v1/private/users/my/account-info');

// 투게더 공지 등록
export const createTogetherNotice = (togetherId, title, content) => axios.post(`/v1/private/togethers/${togetherId}/notices`, { title, content });

// 투게더 공지 수정
export const editTogetherNotice = (togetherId, noticeId, title, content) => axios.put(`/v1/private/togethers/${togetherId}/notices/${noticeId}`, { title, content });

// 투게더 공지 삭제
export const deleteTogetherNotice = (togetherId, noticeId) => axios.delete(`/v1/private/togethers/${togetherId}/notices/${noticeId}`);

// 서버에 이미지 업로드
export const uploadImgToServer = (image) => axios.post('/v1/private/image', image, { headers: { 'Content-Type': 'multipart/form-data' } });

// 출판권을 소유한 미니북 조회
export const getHavePublishingRight = (isPublished) => axios.get('/v1/private/collections/have-publishing-right', { params: { isPublished } });

// 출판권 양도
export const transferPublishingRight = (collectionId, assigneeEmail, language, ratio) => axios.post(`/v1/private/collections/${collectionId}/publishing-right`, { assigneeEmail, language, ratio });

// 수익이 발생한 미니북 목록 조회
export const getHaveEarnings = () => axios.get('/v1/private/collections/have-earnings');

// 정산내역 조회
export const getMySettlementList = (collectionId, language, year, half) => axios.get('/v1/private/users/my/settlements', { params: { collectionId, language, year, half } });

// 아임포트 결제 내역 조회
export const getIamportPaymentDetails = (impUid) => axios.get(`/v1/private/iamport/payments/${impUid}`);

// 특정 미니북, 언어에 대해 정산받을 계좌 타입 수정
export const editAccountType = (collectionId, language, settleWithCorporateAccount) => axios.put(`/v1/private/collections/${collectionId}/${language}/settlement-account`, { settleWithCorporateAccount });

// 미니북에 대한 판매량 조회
export const getStatisticsSales = (collectionId, language, periodInMonths) => axios.get('/v1/private/statistics/sales', { params: { collectionId, language, periodInMonths } });

// 미니북에 대한 독자수 조회
export const getStatisticsReaders = (collectionId, language, periodInMonths) => axios.get('/v1/private/statistics/readers', { params: { collectionId, language, periodInMonths } });

// 미니북에 대한 광고 시청수 조회
export const getStatisticsRents = (collectionId, language, periodInMonths) => axios.get('/v1/private/statistics/rents', { params: { collectionId, language, periodInMonths } });

// 광고 시청후 미니북 보기 허용
export const showMiniBookAfterAd = (collectionId, language) => axios.post(`/v1/private/collections/${collectionId}/rent`, { language });
